<script setup lang="ts">
import { ref } from 'vue';
import type { InputsData } from '../types/inputsData';

const props = defineProps<{
    color: string,
    isMobile?: boolean,
    modelValue: InputsData
}>()

const emit = defineEmits<{
    (event: 'update:modelValue', value: InputsData): void
}>();

const stars = ref([1, 2, 3, 4, 5])

function setStar(star: number) {
    emit('update:modelValue', { ...props.modelValue, value: star, isValid: true })
}

</script>

<template>
    <div class="rate-container">
        <div class="rate">
            <span class="star" v-for="star in stars" :key="star" @click="setStar(star)">
                <u-icon-star class="star-icon"
                    :color="props.modelValue?.isValid ? (star <= Number(props.modelValue?.value) ? props.color : '#f2f2f2') : '#FB000080'"
                    :width="2.563" :height="2.563" :isMobile="false" />
            </span>
        </div>
        <transition name="slide-fade">
            <p class="warning-info" v-if="!props.modelValue?.isValid">
                <span>Укажите хотя бы одну звезду.</span>
            </p>
        </transition>
    </div>
</template>

<style scoped>
.rate-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: clamp(0.625em, 0.625em, 2.667vw);
}

.rate {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.067em;
}

.star {
    display: flex;
    align-items: center;
    width: 2.563em;
    height: 2.563em;
    cursor: pointer;
}

.star-icon {
    transition: fill 0.3s ease, transform 0.3s ease;
}

.star-icon[style*="fill:"] {
    transition: fill 0.3s ease;
}

.warning-info {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    color: #FB0000 !important;
    font-weight: var(--medium);
    font-size: clamp(0.75em, 0.75em, 3.2vw);
}

.warning-info span {
    padding-left: clamp(1.125em, 1.125em, 4.8vw);
}

/* Animation */

.slide-fade-enter-active {
    transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(0.5, 0.5, 0.5, 0.5);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}
</style>
<!-- <script setup lang="ts">
import { defineEmits } from 'vue';

const props = defineProps<{
    color: string,
    isMobile?: boolean,
    modelValue: number
}>()

const emit = defineEmits<{
    (event: 'update:modelValue', value: number): void
}>();

const stars = [1, 2, 3, 4, 5]

function setStar(star: number) {
    emit('update:modelValue', star)
}
</script>

<template>
    <div class="rate">
        <span class="star" v-for="star in stars" :key="star" @click="setStar(star)">
            <u-icon-star class="star-icon" :color="star <= modelValue ? props.color : '#f2f2f2'" :width="2.563"
                :height="2.563" :isMobile="false" />
        </span>
    </div>
</template>

<style scoped>
.rate {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.067em;
}

.star {
    display: flex;
    align-items: center;
    width: 2.563em;
    height: 2.563em;
    cursor: pointer;
}

.star-icon {
    transition: fill 0.3s ease, transform 0.3s ease;
}

.star-icon[style*="fill:"] {
    transition: fill 0.3s ease;
}
</style> -->
