<script setup lang="ts">
import { reactive, onBeforeMount, computed } from 'vue'
import { useOrganizationStore } from '../stores/organizationStore'
import type { OrganizationResponse, Palette } from '../types/organization'
import type { InputsData } from '../types/inputsData'
import { useWindowSize } from '../useWindowSize';
import router from '../router';

const { width } = useWindowSize()
const isMobile = computed(() => width < 431)

const organizationStore = useOrganizationStore()

const state = reactive<{
    organization: OrganizationResponse | null,
    orgCode: string,
    colors: Palette,
    rateInfo: InputsData,
    tags: string[],
    textareaValue: string,
    inputInfo: InputsData | null,
    validData: boolean,
    feedback: string
}>({
    organization: null,
    orgCode: '',
    colors: {
        mainColor: '#FBBC00',
        additionalColor: '#FBBC001F'
    },
    rateInfo: {
        value: 0 || '',
        isValid: true
    },
    textareaValue: '',
    tags: [],
    inputInfo: null,
    validData: true,
    feedback: ''
})

onBeforeMount(async () => {
    const fullUrl = window.location.href;

    const orgCodeMatch = fullUrl.match(/\/(\d{4})#/);

    state.orgCode = orgCodeMatch ? orgCodeMatch[1] : '';

    if (state.orgCode) {
        await organizationStore.getOrganizationData(Number(state.orgCode))

        state.organization = organizationStore.getOrganization

        if (state.organization?.status === 'ok') {
            const color = state.organization.data?.colorMain
            const rgbArr = rgbStringToArray(color)

            if (rgbArr) {
                state.colors.mainColor = rgbToHex(rgbArr[0], rgbArr[1], rgbArr[2])
                state.colors.additionalColor = state.colors.mainColor + '1F'
            }

            if (state.organization.data) {
                state.tags = state.organization.data.feedbackTags
            }
        }
    } else {
        router.push('/error')
    }
})

function rgbStringToArray(rgb: string | undefined): number[] {
    if (!rgb) {
        return []
    }
    return rgb.split(',').map(val => Number(val.trim()));
}

function rgbToHex(r: number, g: number, b: number): string {
    const clamp = (val: number) => Math.max(0, Math.min(255, val));

    const toHex = (val: number) => clamp(val).toString(16).padStart(2, '0');

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

async function sendData() {
    const valid = await checkData()

    if (valid) {
        state.feedback =
`✨ Оценка: ${state.rateInfo.value}★
❤ Короткий отзыв: ${state.tags.length > 0 ? state.tags : 'Не выбрано'}
✍ Отзыв клиента: ${state.textareaValue.length > 0 ? state.textareaValue : 'Не введен'}
📞 Номер телефона: ${state.inputInfo?.value ? 996 : ''} ${state.inputInfo?.value ? state.inputInfo.value : 'Не указан'}`

        const response = await organizationStore.sendFeedback(state.orgCode, state.feedback)

        if (Number(response) === 200) {
            router.push('/success')
        }
    }
}

async function checkData() {
    if (state.rateInfo?.value || !state.rateInfo?.value) {
        if (Number(state.rateInfo.value) > 0) {
            state.rateInfo = { ...state.rateInfo, isValid: true }
            state.validData = true
        } else {
            state.rateInfo = { ...state.rateInfo, isValid: false }
            state.validData = false
        }
    }
    if (state.inputInfo?.value) {
        if (!state.inputInfo?.isValid) {
            state.inputInfo = { ...state.inputInfo, }
            state.validData = false
        } else {
            state.validData = true
        }
    }
    if (state.validData) {
        return true
    }
}

function pickTag(tags: string[]) {
    state.tags = tags
}
</script>

<template>
    <div class="wrapper">
        <div class="form-content">
            <div class="organization-section">
                <div class="org-info">
                    <h4>Заведение</h4>
                    <h2>{{ state.organization?.data?.name }}</h2>
                </div>
                <div class="logo">
                    <img :src="state.organization?.data?.logo" alt="Organization Logo">
                </div>
            </div>
            <form class="feedback-form" @submit.prevent.stop="sendData">
                <div class="form-wrapper">
                    <div class="top-content">
                        <div class="lovely-image"
                            :style="{ backgroundColor: state.colors.mainColor, background: state.colors.additionalColor }">
                            <u-icon-lovely :color="state.colors.mainColor" :width="isMobile ? 12 : 3.125"
                                :height="isMobile ? 12 : 3.125" />
                        </div>
                        <h2>Как вам у нас?</h2>
                        <u-rate :color="state.colors.mainColor" v-model="state.rateInfo" />
                        <Transition name="slide-fade">
                            <u-tags v-if="state.rateInfo?.value && Number(state.rateInfo?.value) > 0"
                                :colors="state.colors" :score="state.rateInfo?.value" :tags="state.tags"
                                :type="['dynamic']" @pickTag="pickTag" />
                        </Transition>
                    </div>
                    <div class="inputs-content">
                        <u-textarea :colors="state.colors" v-model="state.textareaValue" />
                        <u-input :colors="state.colors" v-model="state.inputInfo" />
                        <div class="info">
                            <div class="info-icon">
                                <img src="../assets/icons/info-bold.svg" alt="Information Icon">
                            </div>
                            <p>Номер телефона не обязателен. Этот отзыв является конфиденциальным и попадет напрямую к
                                руководителю.</p>
                        </div>
                    </div>
                </div>
                <u-button :type="['primary']" :color="state.colors.mainColor" />
            </form>
        </div>
    </div>
</template>

<style scoped>
.wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-height: 100vh;
    padding: 1.6em 0;
}

.form-content {
    width: 31.25em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: clamp(1.25em, 1.25em, 5.333vw);
}

.organization-section {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.813em;
}

.inputs-content {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: clamp(0.875em, 0.875em, 3.733vw);
}

.logo {
    width: clamp(2.5em, 2.5em, 9vw);
}

.logo img {
    width: 100%;
    height: auto;
}

.org-info {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.org-info h4 {
    font-size: var(--medium);
    font-size: clamp(0.75em, 0.75em, 2.667vw);
    color: var(--grey-additional);
}

.org-info h2 {
    font-weight: var(--bold);
    font-size: clamp(1em, 1em, 4.267vw);
}

.feedback-form {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    background-color: white;
    min-height: 37.5em;
    border-radius: 2em;
    padding: 1.563em 1.563em 1.688em 1.563em;
    gap: 1.688em;
}

.form-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1.688em;
}

.lovely-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(5.375em, 5.375em, 20vw);
    height: clamp(5.375em, 5.375em, 20vw);
    padding: 1em 0.563em 0.563em 0.688em;
    border-radius: 50%;
}

.lovely-image img {
    width: clamp(3.125em, 3.125em, 13.333vw);
    height: clamp(3.125em, 3.125em, 13.333vw);
}

.top-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1.5em;
}

.top-content h2 {
    font-size: clamp(1.688em, 1.688em, 5.333vw);
    font-weight: var(--extrabold);
}

.info {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    gap: clamp(0.313em, 0.313em, 1.333vw);
    padding: 0 clamp(0.313em, 0.313em, 1.6vw);
}

.info img {
    width: clamp(0.875em, 0.875em, 3.733vw);
    height: auto;
}

.info p {
    font-size: clamp(0.75em, 0.75em, 2.667vw);
    font-weight: var(--regular);
}

/* Animation */

.slide-fade-enter-active {
    transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(-20px);
    opacity: 0;
}

@media screen and (max-width: 431px) {
    .wrapper {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        height: clamp(100%, 100%, 100lvh);
        padding: 0 0;
    }

    .form-content {
        width: 100vw;
        height: 100lvh;
        gap: clamp(1.25em, 1.25em, 5.333vw);
    }

    .organization-section {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 5.333vw 4vw 4vw 4vw;
    }

    .inputs-content {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: clamp(0.875em, 0.875em, 3.733vw);
    }

    .org-info {
        width: 80%;
    }

    .org-info h4 {
        font-size: var(--medium);
        font-size: clamp(0.75em, 0.75em, 2.667vw);
        color: var(--grey-additional);
    }

    .org-info h2 {
        font-weight: var(--bold);
        font-size: clamp(1em, 1em, 4.267vw);
    }

    .feedback-form {
        justify-content: space-between;
        width: 100%;
        min-height: auto;
        height: 100%;
        padding: 6.667vw 4vw 4vw 4vw;
    }

    .lovely-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: clamp(5.375em, 5.375em, 20vw);
        height: clamp(5.375em, 5.375em, 20vw);
        padding: 1em 0.563em 0.563em 0.688em;
        border-radius: 50%;
    }

    .lovely-image img {
        width: clamp(3.125em, 3.125em, 13.333vw);
        height: clamp(3.125em, 3.125em, 13.333vw);
    }

    .top-content {
        gap: 6.667vw;
    }

    .info {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        gap: clamp(0.313em, 0.313em, 1.333vw);
        padding: 0 clamp(0.313em, 0.313em, 1.6vw);
    }

    .info img {
        width: clamp(0.875em, 0.875em, 3.733vw);
        height: auto;
    }

    .info p {
        font-size: clamp(0.75em, 0.75em, 2.667vw);
        font-weight: var(--regular);
    }
}
</style>
