<script setup lang="ts">
import { ref, onBeforeMount } from 'vue';

const props = defineProps<{
    color: string,
    width: number,
    height?: number,
    isMobile?: boolean
}>();

const unit = ref('em');
const hover = ref(false);
const clicked = ref(false);

onBeforeMount(() => {
    unit.value = props.isMobile ? 'vw' : 'em';
});

const hoverIcon = () => {
    if (props.color === '#f2f2f2' && !clicked.value) {
        hover.value = true;
    }
};

const clearHover = () => {
    hover.value = false;
};

const clickIcon = () => {
    clicked.value = true;
};

</script>

<template>
    <svg class="star-icon"
        :style="{ fill: clicked ? props.color : (hover ? '#E6E6E6' : props.color), width: props.width + unit, height: props.height + unit }"
        @mouseover.stop="hoverIcon" @mouseleave="clearHover" @click="clickIcon" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.8413 7.9988C25.6021 1.10717 15.8523 1.10717 13.613 7.9988C12.9547 10.025 11.0665 11.3969 8.93599 11.3969C1.6897 11.3969 -1.32316 20.6695 4.53921 24.9288C6.26282 26.181 6.98404 28.4007 6.32568 30.427C4.08646 37.3186 11.9742 43.0494 17.8366 38.7901C19.5602 37.5378 21.8941 37.5378 23.6177 38.7901C29.4801 43.0494 37.3679 37.3186 35.1287 30.427C34.4703 28.4007 35.1915 26.181 36.9151 24.9288C42.7775 20.6695 39.7646 11.3969 32.5184 11.3969C30.3879 11.3969 28.4997 10.025 27.8413 7.9988Z" />
    </svg>
</template>

<style scoped>
.star-icon {
    transition: fill 0.3s ease, transform 0.3s ease;
}
</style>
