<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';

const props = defineProps<{
    color: string,
    width: number,
    height?: number,
    isMobile?: boolean
}>();

const unit = ref('em')

onBeforeMount(() => {
    unit.value = props.isMobile ? 'vw' : 'em'
})
</script>

<template>
    <svg :style="{ fill: props.color, width: props.width + unit, height: props.height + unit }" viewBox="0 0 50 51"
        xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4"
            d="M41.3748 17.5486C41.3748 17.8611 41.3749 18.1737 41.354 18.4653C38.1665 17.2778 34.4165 18.007 31.9165 20.257C30.229 18.7362 28.0415 17.882 25.7082 17.882C20.5832 17.882 16.4165 22.0695 16.4165 27.2362C16.4165 33.132 19.3748 37.4445 22.2082 40.2361C21.979 40.2153 21.7915 40.1737 21.6248 40.1112C16.229 38.257 4.1665 30.5903 4.1665 17.5486C4.1665 11.7986 8.79149 7.15283 14.4998 7.15283C17.8957 7.15283 20.8957 8.77781 22.7707 11.2986C24.6665 8.77781 27.6665 7.15283 31.0415 7.15283C36.7498 7.15283 41.3748 11.7986 41.3748 17.5486Z" />
        <path
            d="M37.5002 20.6737C35.2711 20.6737 33.2502 21.7571 32.0002 23.4237C30.7502 21.7571 28.7502 20.6737 26.5002 20.6737C22.7086 20.6737 19.6252 23.7571 19.6252 27.5904C19.6252 29.0696 19.8544 30.4237 20.2711 31.6737C22.2294 37.8612 28.2502 41.5487 31.2294 42.5696C31.646 42.7154 32.3336 42.7154 32.7711 42.5696C35.7502 41.5487 41.771 37.8612 43.7294 31.6737C44.146 30.4029 44.3752 29.0487 44.3752 27.5904C44.3752 23.7571 41.2919 20.6737 37.5002 20.6737Z" />
    </svg>
</template>

<style scoped></style>
