<script setup lang="ts">
import { withDefaults } from 'vue'

const props = withDefaults(defineProps<{
    type: string[],
    color: string
}>(), {
    type: () => ['primary', 'remove']
})

const emit = defineEmits<{
    (event: 'removeValue', value: boolean): void
}>()

const removeValue = function () {
    emit('removeValue', true)
}
</script>

<template>
    <button v-if="props.type.includes('remove')" @click.prevent="removeValue" class="remove-btn"><img
            src="../assets/icons/remove.svg" alt=""></button>
    <button v-if="props.type.includes('primary')" class="primary-button"
        :style="{ backgroundColor: props.color }">Отправить</button>
</template>

<style scoped>
.button-content {
    width: 100%;
}

.remove-btn {
    all: unset;
    cursor: pointer;
}

.remove-btn img {
    position: absolute;
    z-index: 3000;
    top: 0;
    right: 0;
    margin: clamp(1.125em, 1.125em, 5.333vw);
    background: transparent;
    border: none;
    cursor: pointer;
}

.primary-button {
    width: 100%;
    height: clamp(3.75em, 3.75em, 16vw);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: clamp(1em, 1em, 4.8vw);
    font-weight: var(--bold);
    color: white;
    border-radius: clamp(1.563em, 1.563em, 0.417vw);
    cursor: pointer;
}
</style>
