<script setup lang="ts">
</script>

<template>
    <div class="error-page">
        <div class="error-block">
            <div class="icon-container">
                <img src="../assets/icons/404.svg" alt="404 Image" class="error-image">
            </div>
            <div class="error-info">
                <h2>Страница не найдена</h2>
            </div>
        </div>
    </div>
</template>

<style scoped>
.error-page {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-height: 100vh;
    padding: 1.6em 0;
}

.error-block {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 34.375em;
    height: auto;
    padding: clamp(3.125em, 3.125em, 8vw) clamp(2.5em, 2.5em, 8vw) clamp(3.125em, 3.125em, 13.333vw) clamp(2.5em, 2.5em, 8vw);
    border-radius: clamp(2.188em, 2.188em, 9.333vw);
    background-color: white;
    gap: clamp(0.938em, 0.938em, 2.133vw);
}

.error-image img {
    width: clamp(7.375em, 7.375em, 27.442vw);
    height: auto;
}

.error-info {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: clamp(0.938em, 0.938em, 2.133vw);
}

.error-info h2 {
    font-size: clamp(1.875em, 1.875em, 6.133vw);
    font-weight: var(--extrabold);
}

.error-info p {
    font-size: clamp(1.375em, 1.375em, 4vw);
    font-weight: var(--regular);
    color: var(--grey-additional);
    text-align: center;
}

@media screen and (max-width: 431px) {
    .error-block {
        width: 92vw;
        padding: 8vw 8vw 13.333vw 8vw;
        border-radius: 9.333vw;
        gap: 2.133vw;
    }

    .error-image img {
        width: 27.442vw;
    }

    .error-info {
        gap: 2.133vw;
    }

    .error-info h2 {
        font-size: 6.133vw;
    }

    .error-info p {
        font-size: 4vw;
    }
}
</style>
