<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';

const props = defineProps<{
    color: string,
    width: number,
    height?: number,
    isMobile?: boolean
}>();

const unit = ref('em')
const circleSize = ref(0)

onBeforeMount(() => {
    unit.value = props.isMobile ? 'vw' : 'em'
    circleSize.value = props.isMobile ? 21 : 5.375
})
</script>

<template>
    <svg :style="{ fill: props.color, width: props.width + unit, height: props.height + unit }" viewBox="0 0 86 87"
        xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" :style="{ fill: props.color, width: circleSize + unit, height: circleSize + unit }" rx="43"
            fill-opacity="0.2" />
        <path
            d="M45.7086 35.3958L48.4586 40.8958C48.8336 41.6458 49.8336 42.3957 50.6669 42.5207L55.6461 43.3541C58.8336 43.8957 59.5836 46.1874 57.2919 48.4791L53.4169 52.3541C52.7711 52.9999 52.3961 54.2708 52.6044 55.1874L53.7086 59.9999C54.5836 63.7916 52.5628 65.2708 49.2086 63.2916L44.5419 60.5208C43.6877 60.0208 42.3128 60.0208 41.4586 60.5208L36.7919 63.2916C33.4377 65.2708 31.4169 63.7916 32.2919 59.9999L33.3961 55.1874C33.6045 54.2916 33.2294 53.0208 32.5836 52.3541L28.7086 48.4791C26.417 46.1874 27.1669 43.8749 30.3544 43.3541L35.3336 42.5207C36.1669 42.3749 37.1669 41.6458 37.5419 40.8958L40.2919 35.3958C41.7711 32.4166 44.2294 32.4166 45.7086 35.3958Z"
            :style="{ fill: props.color }" />
        <path opacity="0.4"
            d="M30.5 38.8126C29.6458 38.8126 28.9375 38.1042 28.9375 37.2501V22.6667C28.9375 21.8126 29.6458 21.1042 30.5 21.1042C31.3542 21.1042 32.0625 21.8126 32.0625 22.6667V37.2501C32.0625 38.1042 31.3542 38.8126 30.5 38.8126Z"
            :style="{ fill: props.color }" />
        <path opacity="0.4"
            d="M55.5 38.8126C54.6458 38.8126 53.9375 38.1042 53.9375 37.2501V22.6667C53.9375 21.8126 54.6458 21.1042 55.5 21.1042C56.3542 21.1042 57.0625 21.8126 57.0625 22.6667V37.2501C57.0625 38.1042 56.3542 38.8126 55.5 38.8126Z"
            :style="{ fill: props.color }" />
        <path opacity="0.4"
            d="M43 28.3959C42.1458 28.3959 41.4375 27.6876 41.4375 26.8334V22.6667C41.4375 21.8126 42.1458 21.1042 43 21.1042C43.8542 21.1042 44.5625 21.8126 44.5625 22.6667V26.8334C44.5625 27.6876 43.8542 28.3959 43 28.3959Z"
            :style="{ fill: props.color }" />
    </svg>

</template>

<style scoped></style>