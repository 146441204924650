<script setup lang="ts">
import { reactive, onBeforeMount, computed } from 'vue'
import { useOrganizationStore } from '../stores/organizationStore'
import type { OrganizationResponse, Palette } from '../types/organization'
import { useWindowSize } from '../useWindowSize';
const { width } = useWindowSize()
const isMobile = computed(() => width < 431)

const organizationStore = useOrganizationStore()

const state = reactive<{
    organization: OrganizationResponse | null,
    orgCode: string,
    colors: Palette,
}>({
    organization: null,
    orgCode: '',
    colors: {
        mainColor: '#FBBC00',
        additionalColor: '#FBBC001F'
    },
})

onBeforeMount(async () => {
    await organizationStore.getOrganizationData(2331)

    state.organization = organizationStore.getOrganization

    if (state.organization?.status === 'ok') {
        const color = state.organization.data?.colorMain
        const rgbArr = rgbStringToArray(color)

        if (rgbArr) {
            state.colors.mainColor = rgbToHex(rgbArr[0], rgbArr[1], rgbArr[2])
        }
    }
})

function rgbStringToArray(rgb: string | undefined): number[] {
    if (!rgb) {
        return []
    }
    return rgb.split(',').map(val => Number(val.trim()));
}

function rgbToHex(r: number, g: number, b: number): string {
    const clamp = (val: number) => Math.max(0, Math.min(255, val));

    const toHex = (val: number) => clamp(val).toString(16).padStart(2, '0');

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
</script>

<template>
    <div class="success-page">
        <div class="success-block">
            <div class="icon-container">
                <u-icon-success :color="state.colors.mainColor" :width="isMobile ? 20 : 5.375"
                    :height="isMobile ? 20 : 5.375" :isMobile="isMobile"  />
            </div>
            <div class="success-info">
                <h2>Спасибо за отзыв!</h2>
                <p>Если вы указали номер телефона, мы обязательно свяжемся с вами.</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.success-page {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-height: 100vh;
    padding: 1.6em 0;
}

.success-block {
    max-width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: clamp(34.375em, 34.375em, 92vw);
    height: auto;
    padding: clamp(2.5em, 2.5em, 8vw) clamp(2.5em, 2.5em, 8vw) clamp(3.125em, 3.125em, 13.333vw) clamp(2.5em, 2.5em, 8vw);
    border-radius: clamp(2.188em, 2.188em, 9.333vw);
    background-color: white;
    gap: clamp(0.938em, 0.938em, 2.133vw);
}

.success-info {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: clamp(0.938em, 0.938em, 2.133vw);
}

.success-info h2 {
    font-size: clamp(1.875em, 1.875em, 6.133vw);
    font-weight: var(--extrabold);
}

.success-info p {
    font-size: clamp(1.375em, 1.375em, 4vw);
    font-weight: var(--regular);
    color: var(--grey-additional);
    text-align: center;
}
</style>
