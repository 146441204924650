import { reactive, onMounted, onBeforeUnmount } from 'vue'

interface WindowSize {
  width: number
  height: number
}

const windowSize = reactive<WindowSize>({
  width: window.innerWidth,
  height: window.innerHeight
})

const updateWindowSize = () => {
  windowSize.width = window.innerWidth
  windowSize.height = window.innerHeight
}

export function useWindowSize() {
  onMounted(() => {
    window.addEventListener('resize', updateWindowSize)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateWindowSize)
  })

  return windowSize
}
