<script setup lang="ts">
import { reactive, onBeforeMount, watch } from 'vue'
import type { Palette } from '../types/organization'

const emit = defineEmits<{
    (event: 'pickTag', value: string[]): void
}>()

const props = withDefaults(defineProps<{
    colors: Palette,
    score: number,
    type: string[],
    tags: string[]
}>(), {
    score: 0,
    type: () => ['dynamic', 'default']
})

const state = reactive({
    allTags: [''],
    pickedTag: '',
    pickedTags: ['']
})

onBeforeMount(() => {
    if (props.score > 0) {
        getReviewData()
    }
})

function getReviewData() {
    state.allTags = props.tags
}

watch(() => props.score, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        if (props.score > 0) {
            getReviewData()
            triggerAnimation()
        }
    }
})

watch(() => state.pickedTags, (newValue) => {
    emit('pickTag', newValue)
})

function triggerAnimation() {
    const tagsContainer = document.querySelector('.tags');
    if (tagsContainer) {
        tagsContainer.classList.add('animate');
        setTimeout(() => {
            tagsContainer.classList.remove('animate');
        }, 500)
    }
}
</script>

<template>
    <div class="tags">
        <u-tag v-for="tag in state.allTags" :key="tag" :name="tag" :colors="props.colors" v-model="state.pickedTags" />
    </div>
</template>

<style scoped>
.tags {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: clamp(0.313em, 0.313em, 1.6vw);
}

.animate {
    animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-10px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
</style>