import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ErrorView from '@/views/ErrorView.vue'
import SuccessView from '@/views/SuccessView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main',
    component: HomeView
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  try {
    const isConnected = navigator.onLine
    if (!isConnected && to.name !== 'error') {
      next({ name: 'error' })
    } else if (to.matched.length === 0) {
      next({ name: 'error' })
    } else {
      if (from.meta && to.fullPath !== from.fullPath) {
        from.meta.scrollPosition = {
          x: window.scrollX,
          y: window.scrollY
        }
      }
      next()
    }
  } catch (error) {
    next({ name: 'error' })
  }
})

export default router
