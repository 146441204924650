<script setup lang="ts">
import { reactive } from 'vue'
import { Palette } from '../types/organization'

const props = defineProps<{
    name: string,
    colors: Palette,
    modelValue?: string[]
}>()

const state = reactive({
    pickedTag: '',
    pickedTags: props.modelValue || [],
    isPicked: false
})

const emit = defineEmits<{
    (event: 'update:modelValue', value: string[]): void;
}>()

function chooseTag(name: string) {
    const existedTag = state.pickedTags?.find(e => e === name)

    if (existedTag) {
        const index = state.pickedTags?.indexOf(name)

        if (index !== undefined && index > -1) {
            state.pickedTags?.splice(index, 1)
            state.isPicked = false
            emit('update:modelValue', state.pickedTags)
        }
    } else {
        state.pickedTags?.push(name)
        state.isPicked = true
        emit('update:modelValue', state.pickedTags)
    }
}
</script>

<template>
    <transition name="fade">
        <span v-show="true" @click="chooseTag(props.name)" :class="['tag']"
            :style="{ backgroundColor: state.isPicked ? props.colors.additionalColor : '', color: state.isPicked ? props.colors.mainColor : '' }">
            {{ props.name }}
        </span>
    </transition>
</template>

<style scoped>
.tag {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-content: center;
    font-size: clamp(0.75em, 0.75em, 3.733vw);
    font-weight: var(--regular);
    background-color: var(--grey);
    padding: clamp(0.8em, 0.8em, 2.7vw) clamp(1em, 1em, 3.2vw);
    border-radius: 5em;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}
</style>
