import { type App } from 'vue'
import UIconLovely from './icons/UIconLovely.vue'
import UIconStar from './icons/UIconStar.vue'
import UIconSuccess from './icons/UIconSuccess.vue'
import URate from './URate.vue'
import UTags from './UTags.vue'
import UTag from './UTag.vue'
import UInput from './UInput.vue'
import UTextarea from './UTextarea.vue'
import UButton from './UButton.vue'

const components = [
  // Icon Components
  {
    name: 'u-icon-lovely',
    component: UIconLovely
  },
  {
    name: 'u-icon-star',
    component: UIconStar
  },
  {
    name: 'u-icon-success',
    component: UIconSuccess
  },
  // UI Components
  {
    name: 'u-rate',
    component: URate
  },
  {
    name: 'u-tags',
    component: UTags
  },
  {
    name: 'u-tag',
    component: UTag
  },
  {
    name: 'u-textarea',
    component: UTextarea
  },
  {
    name: 'u-input',
    component: UInput
  },
  {
    name: 'u-button',
    component: UButton
  }
]

export function registerGlobalComponents(app: App) {
  components.forEach(({ name, component }) => {
    app.component(name, component)
  })
}
