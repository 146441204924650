<script setup lang="ts">
import { reactive } from 'vue';
import type { Palette } from '../types/organization'

const emit = defineEmits(['update:modelValue'])

const props = defineProps<{
    modelValue: string,
    colors: Palette
}>()

const state = reactive({
    textValue: props.modelValue,
    isFocus: false
})

function clearInfo(value: boolean) {
    if (value) {
        state.textValue = '';
        emit('update:modelValue', '')
    }
}
</script>

<template>
    <div class="textarea-container">
        <textarea :style="{ outlineColor: state.isFocus ? props.colors.additionalColor : 'transparent' }"
            @focus="state.isFocus = true" @blur="state.isFocus = false" class="textarea" v-model="state.textValue"
            @input="emit('update:modelValue', state.textValue)" placeholder="Оставьте ваш отзыв">
        </textarea>
        <u-button :type="['remove']" @removeValue="clearInfo" v-if="state.textValue.length > 0" />
    </div>
</template>

<style scoped>
.textarea {
    width: 100%;
    height: clamp(6.875em, 6.875em, 32vw);
    display: flex;
    flex-flow: row nowrap;
    color: var(--c-black);
    font-family: 'Commissioner', sans-serif;
    font-weight: var(--regular);
    font-size: clamp(0.938em, 0.938em, 4vw);
    background-color: var(--grey);
    resize: none;
    cursor: text;
    overflow-wrap: normal;
    padding: clamp(1.125em, 1.125em, 5.333vw);
    border: none;
    border-radius: clamp(1.438em, 1.438em, 6.667vw);
    transition: 0.2s ease-in;
}

.textarea:focus {
    outline: 0.3em solid;
    transition: 0.2s ease-in;
    background-color: white;
}

.textarea::placeholder {
    color: var(--grey-additional);
}

.textarea-container {
    width: 100%;
    height: clamp(6.875em, 6.875em, 32vw);
    position: relative;
}
</style>
